@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Open+Sans:ital,wght@0,300;0,400;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
.ExpandedAdviceBlock {
    padding: 6px 24px 6px 24px;
    background-color: #eee;
    font-size: small;
    border-bottom: solid 1px #333;
}
/* bullets handled manually, but keep using ul/ol for better copy/paste*/

:root {
    --primary: #007fff;
}

ul {
    list-style-type: none;
}

ol {
    list-style-type: none;
}

*[data-bullet] {
    position: relative;
}

*[data-bullet]:before {
    content: attr(data-bullet);
    position: absolute;
    left: -20px;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.cell-text {
    resize: none;
    border: none;
    padding: 5px;
}

.cell {
    background-color: white;
    padding-left: 2px;
    padding-right: 2px;
    border: 1px solid black;
    vertical-align: top;
}

.cell-div {
    white-space: pre-line;
    overflow-y: hidden;
}

.cell-div:hover {
    overflow-y: auto;
}

.line-item-table {
    font-size: 10px;
    table-layout: fixed;
}

.indented {
    margin-left: 40px;
}

.para {
    min-height: 8px;
    margin-bottom: 6px;
}

.hide-bullet {
    list-style-type: none;
    counter-increment: none;
}

.preserve-spaces {
    white-space: pre-wrap;
}

.scrollX {
    overflow-x: auto;
}

.line-item-table-container {
    margin-bottom: 10px;
}

/* Scrollbar always visible on Mac */

.line-item-table-container::-webkit-scrollbar {
    -webkit-appearance: none;
}
/*
.line-item-table-container::-webkit-scrollbar:vertical {
    width: 11px;
}
*/
.line-item-table-container::-webkit-scrollbar:horizontal {
    height: 11px;
}

.line-item-table-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}
/* end scrollbar */

.ph-highlight {
    color: #007fff;
    color: var(--primary);
}

.contract {
    font-size: 85%;
}

.mrc-section-heading, .line-item-name {
    font-weight: bold;
}

.mrc-line-item {
    padding: .75rem 1.25rem;
    border-bottom: 2px solid #eee;
}
/* ajc interfered with other selects
.Module-reinsurance, select {
    padding: .5rem;
    margin: 0 .5rem;
    cursor: pointer;
}
*/

.modal i {
    color: grey;
}

/* ajc was causing McGill EOC Popup alignment problem
.modal .col:nth-child(2) {
    padding: 1.7rem 1rem
}

.modal .col:nth-child(3) {
    padding: 1rem
}
*/

.modal .victoria {
    text-align: center;
    font-size: 3rem;
    display: block;
    float: left;
    margin-left: 0.85rem;
    font-weight: bold;
    color: blue;
}

.modal .line {
    border-right: 3px solid blue;
}
.rdt_TableHeader {
    display: none !important
}

.table-filter-wrapper {
    text-align: center;
}

.highlighted {
    background-color: skyblue;
    transition: background-color 1s linear;
}

.table-filter {
    font-size: larger;
    padding: .3em;
    margin: 0em 1em;
}

.clear-filter {
    font-size: larger;
    margin-top: -.3em;
}

.btn-grey {
    background-color: grey !important;
    border: 1px solid grey !important;
}
h4 {
    display: block;
    margin-top: 0.3em;
}

.Search-options {
    display: block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: .5rem;
    width: 40rem;
    background: whitesmoke;
    margin: 1rem auto;
}

.Search-form {
    width: 80%;
    margin: auto;
    text-align: center;
    white-space: nowrap;
}

.submit-btn {
    min-width: 10em;
}

.custom-range {
    width: 80%;
    height: 0em;
}
.Modify-text, .Modify-textarea {
    margin-top: .5em;
    width: 100%;
    padding: .5em;
}

.Option {}

.Option-title {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: -0.5rem;
    font-size: 1.2rem;
}

.Col-select {
    border: 1px solid #007bff;
    margin: 1rem 0.5rem;
    padding: 0rem;
    overflow: hidden;
    color: #007bff;
}

.Col-select:hover, .Col-select.selected {
    cursor: pointer;
    background-color: #007bff;
}

.Col-select:hover, .Col-select.selected {
    color: white;
}

.Col-select .header {
    display: block;
    padding: 0.5rem;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    border-bottom: 1px solid #007bff;
}

.Col-select:hover .header, .Col-select.selected .header {
    border-bottom: 1px solid white;
}

.Col-select .body {
    padding: 0.5rem 1rem;
}

.Col-select:nth-child(1) {
    border-radius: 1rem 0rem 0rem 1rem;
    margin-right: 0rem;
}

.Col-select:nth-child(2) {
    border-radius: 0rem 1rem 1rem 0rem;
    margin-left: 0rem;
    border-left: none;
}
.Pill, .PillDisabled {
    
    color: white;
    font-size: small;
    border-radius: 5px;
    padding: 5px;
    margin-right: 6px;
    margin-top: 6px;
    float: left;
}

.Pill {
    background-color: coral;
}

.PillDisabled
{
    background-color: #eee;
}

.QuietBorder
{
    background-color: #eee;
    border: solid 1px #ddd;
    padding: 2px;
    margin-top: 2px;
}

.Width100
{
    width: 100px;
}
.JSONResult {
    border: solid 1px green;
    padding: 2px;
    font-size: small;
    background-color: lightgreen;   
}

.PayloadTA {
    width: 100%;
    height: 100px;
}

.EndpointComment
{
    font-size: small;
    color: darkred;
}
.JSONResult {
    border: solid 1px green;
    padding: 2px;
    font-size: small;
    background-color: lightgreen;   
}

.ServiceBlock {
    font-size: small;
    border: solid 1px #ccc;
    padding: 2px;
    color: black;
}

.ServiceStatusPENDING {
    padding: 0 2px 0 2px;
    background-color:lightblue;
}
.ServiceStatusERROR {
    padding: 0 2px 0 2px;
    background-color: red;
}
.ServiceStatusPASS {
    padding: 0 2px 0 2px;
    background-color:lightgreen;
}
.ServiceStatusFAIL {
    padding: 0 2px 0 2px;
    background-color:darkred;
    color: white;
}
.ColHeader {
    text-align: center;
    border-right: solid 1px black;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    padding: 12px 0 12px 0;
}
.Col1Header {
    background-color: #eef;
    border-left: solid 1px black;
}
.Col2Header {
    background-color: #ddf;
}
.Col3Header {
    background-color: #ccf;
}
.Col4Header {
    background-color: #bbf;
}
.ColData {
    border-right: solid 1px black;
    border-bottom: solid 1px black;
}
.Col1Data {
    background-color: #eef;
    border-left: solid 1px black;
}
.Col2Data {
    background-color: #ddf;
}
.Col3Data {
    background-color: #ccf;
}
.Col4Data {
    background-color: #bbf;
}
.MyTinyText, .TaxMainTitle, .TaxSubTitle
{
    font-size:x-small;
}
.TaxMainTitle
{
    font-weight: bold;
    text-decoration: underline;
}
.TaxSubTitle
{
    text-decoration: underline;
}
.ForceNumericLI {   /* MRCViewer hides number */
    list-style-type: decimal;
} 
.JSONResult {
    border: solid 1px green;
    padding: 2px;
    font-size: small;
    background-color: lightgreen;   
}

.PayloadTA {
    width: 100%;
    height: 600px;
    font-size: small;
}
.data-table {
    display: flex;
    justify-content: left;
}

.table-wrapper {
    width: 50%;
}

.input-field {
    height: 36px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 3px 16px;
}

.input-field:hover {
      cursor: pointer;
}

/* .clear-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 35px;
    text-align: center;
} */

.Highlight1 {
    background-color: pink;
}

.TeamDetail {
    font-size: small;
    margin: 0 0 6px 60px;
}
.fetched {
	border: solid 1px #bbb;
	font-size:small;
	padding: 3px;
	margin-left: 6px;
}
.fetched-Uncalled {
	background-color: yellow;	
}

.fetched-OK {
	background-color: lightgreen;
}

.fetched-Calling {
	background-color: lightblue;
}


.fetched-Failed {
	background-color: coral;
}

.CDRadioButton {
	float:left;
	border: solid 2px blue;
	border-radius: 6px;
	padding: 6px;
	text-align: center;
	margin-right: 6px;
	margin-bottom: 6px;
	cursor: pointer;
}

.CDSelected {
	background-color: #aff;
}
.SectPicker:hover
{
	background-color: lightskyblue;
}
.SectSelected, .SectPicker {
	margin-top: 12px;
	
	border-radius: 6px;
	padding: 6px;
}

.SectSelected {
	border: solid 3px blue;
}

.SectPicker {
	border: solid 3px skyblue;
}

.ShowMe {
	background-color: skyblue;
	font-size: small;
	margin-left: 12px;
	padding-left: 12px;
	padding-right: 12px;
	color: white;
}
.HideMe {
	display: none;
}
.InputTA {
    width: 100%;
    height: 300px;
    font-size: small;
}

.ItemTitle {
    font-weight: bold;
}

.NegativeFigure {
    color: red;
}

.CarrierLogo {
    width: 120px;
    height: 120px;
}

.FeedbackAlert {
    padding: 2px 6px 2px 6px;
    font-size: small;
}
.FeedbackNone {
    border: solid 1px black;
    background-color: white;
    color: black;
}
.FeedbackAgree {
    border: solid 1px green;
    background-color: green;
    color: white;
}
.FeedbackDisagree {
    border: solid 1px red;
    background-color: red;
    color: white;
}

.FeedbackAlert:hover {
    border: solid 1px blue;
    background-color: blue;
    color: white;   
    cursor: pointer;
}
.DlgSpacer {
    margin-top: 12px;
    border-top: dashed 2px lightgray;
    margin-bottom: 12px;
}
.DlgTextArea {
    width: 100%;
    height: 120px;
}

.StrikeThrough {
    text-decoration: line-through;
}
.Italics {
    font-style: italic;   
}
/*
.DebugBackground {
    background-color: lightblue;
}
*/
.PassColour {
	background-color: lightgreen;
}
.FailColour {
	background-color: lightpink;
}
.OptionalFailColour {
	background-color: lightgoldenrodyellow;
}
.OptionalAbsentColour {
	background-color: lightgray;
	text-decoration: line-through;
}
.ConditionalAbsentColour {
	background-color: gray;
	text-decoration: line-through;
}

.NotApplicableColour {
	background-color: lightblue;
}

.SummaryTestSpan {
	padding-left: 2px;
	padding-right: 2px;
	margin-right: 2px;
}
.HeaderRow {
	background-color: #222;
	color: white;
}

.SummaryRow {
	border-bottom: solid 1px #333;
}
.SummaryRow, .TestGridRow {
	font-size: small;
}

.CDRBlob {
	border-radius: 6px;
	padding: 6px;
	
	margin-bottom: 6px;
}

.CDRBlobImplemented {
	background-color: #ddf;
	border: solid 1px black;
}
.CDRBlobPartiallyImplemented {
	background-color: #bbf;
	border: solid 1px black;
}
.CDRBlobNotYetImplemented {
	background-color: eee;
	border: dotted 1px gray;
	color: #aaa;
}

.CDRBlob H3 {
	font-size: 1.4em;
}

.JSONResult {
    border: solid 1px green;
    padding: 2px;
    font-size: small;
    background-color: lightgreen;   
}
.JSONViewer {
    width: 100%;
    height: 1000px;
    font-size: small;
}

.OneRule {
    border: solid 1px lightgray;
    border-radius: 6px;
    padding: 6px;
}
.UMRList {
	width: 100%;
	height: 100px;
}

.NormalCell {
	
}

.ErrorCell {
	background-color: lightpink;
}

.SuccessCell {
	background-color: lightgreen;
}
.TeamSelected {
	background-color: lightblue;
}
.Indented1 {
	margin-left: 36px;
}
.Indented2 {
	margin-left: 72px;
}



/* 16:40 on demo branch */
.VersionMessage {
    font-size: small;
    color: lightgray;
}
/*
.App {
  text-align: center;
}
*/

.Centered {
    text-align: center;
}

.Session-info {
  display: inline;
  font-family: 'Open Sans', Courier, monospace;
  font-size: .7em;
  font-weight: 700;
  float: right;
  padding-top: 0.3em;
}

.Ws-logo {
  width: 50%;
  margin: 2rem auto;
  pointer-events: none;
}

.Client-logo {
  width: 90%;
  margin: 2rem auto;
  pointer-events: none;
}

.Login-form {
  text-align: center;
  padding: 1rem;
}

.Page-loading > div {
  padding-top: 20vh;
  margin: auto;
  zoom: 3;
}

.Subpage-loading {
  padding: 2vh;
  text-align: center;
  zoom: 3;
}

.notification-container {
  padding: 0 30%;
  width: 100%;
}

.Img-fullpage {
  display: block;
  margin: auto;
}

.highlighted {
  background-color: yellow
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Titlebar {
  background: #FAFAFA;  
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  font-weight: bold;
  font-size: 1.8rem;
  width: 100%;
  border-bottom: 1px solid #999;
  display: block;
  padding: .5em 1em;
}

.Page {
  text-align: left;
  padding: 1rem 1rem 1rem 1rem;    
  width: 100%;
}

.Navbar {
  float: left;
  z-index: 1;
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  width: 14rem;
  font-size: 1.5rem;
  background-color: white;
  border-right: 1px solid #999;
}

.NavbarCollapsed {
    float: left;
    padding: 0 2px 0 2px;
    position: fixed;
    min-height: 100vh;
    z-index: 1;
    min-height: 100vh;
    font-family: 'Open Sans', sans-serif;
    width: 1rem;
    font-size: 1.5rem;
    background-color: white;
}

.Navigation-link {
  display: block;
  text-decoration: none !important;
  color: #333;  
}

.NavBottomBorder {
  border-bottom: 1px solid #999;
}

.NavigationLinkNormal {
  font-size: 1.2rem;
  padding: 1.2rem .5rem;
}
.NavigationLinkCompressed {
  font-size: 0.6em;
  padding: 0.1rem .5rem;
}

.Navigation-link:nth-of-type(1) {
  border-top: 1px solid #999;
}

.Navigation-link.selected,
.Navigation-link:hover {
  cursor: pointer;
  background: #ecf1ff;  
  transition: background-color 0.5s linear;
}

.Beta-tag {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.6em;
  color: white;
  position: absolute;
  display: ruby;
  border-radius: .1em;
  padding: 0em 0.5em;
  background-color: green;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
  z-index: 1;
}

.Logout {
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: white;
  /* position: fixed;
  bottom: 2rem;
  left: 3rem;
  width: 6rem; */
}

.External-backdrop {
  width: 100vw;
  height: 100vh;
  background-image: url(../../static/media/spikes.ab304b3d.png);
}

.Fadein-wrapper {
  opacity: 0;
}
.Fadein-wrapper.load {
  opacity: 1;
  transition: opacity 1s linear;
}

.Risk-wrapper {
  margin: 1em 0em;
}

.Risk-wrapper .Risk-section {
  vertical-align: text-bottom;
}

.Risk-wrapper .Risk-section div {
  padding: 0.5em 1em;
}

.Risk-wrapper .Risk-section .title {
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: bold;
  display: inline-block;
  text-align: right;
  margin-top: -0.2em
}

.Risk-wrapper .Risk-section .content {
  display: inline-block;
}

.page-notice {
  text-align: center;
  font-size: 2rem;
}

.modal-footer {
  display: block;
  text-align: center;
}

.Above1 {
    margin-top: 1px;
}
.Above2 {
  margin-top: 2px;
}
.Above12 {
    margin-top: 12px;
}
.Below12 {
    margin-bottom: 12px;
}
.MyIcon {
    color:blue;
}
.MyIcon:hover, .ClickableRow:hover {
    background-color: lightskyblue;
}
.MyLink {
    color:blue
}
.MyLink:hover {
    cursor: pointer;
    text-decoration: underline;
}
.IconLink, .LightGray 
{
    color: lightgray;
}
.IconLink:hover
{
    color: black;
}
.FloatRight {
    float: right;
}
.FloatLeft {
    float: left;
}
.ClearBoth {
    clear: both;
}
.DlgSubTitle, .MySubTitle {
    font-size: small;
    font-weight: bold;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
}
.FullWidth {
    width: 100%;
}

.SmallText {
  font-size: small;
}
.NavTiny, .XSmallText {
    font-size: x-small;
}
.RiskGridSingleLine .rdt_TableRow {
    cursor: pointer;
}
.RiskGridSingleLine .rdt_TableRow:hover {
    background-color: blanchedalmond;
}
.WarningText
{
    color: darkred;
    font-weight: bold;
}
.SuccessText
{
    color: green;
    font-weight: bold;
}
.LogoContainer {
    padding: 5px;
}

.TVTitle {
  color: darkgreen;
  padding: 3px 0 3px 0;
}
.TVPre {
  border: solid 1px darkgreen;
  background-color: lightgreen;
  font-size: small;
}
.TVPane {
  border-left: solid 1px #dee2e6;
  border-right: solid 1px #dee2e6;
  border-bottom: solid 1px #dee2e6;
  padding: 6px;
  margin-top: 0px;
  display: inline-grid;
}

.page-layout {
display: flex;
}

.page-wrapper {
  width: 100vw;
}

.WarningBanner {
  text-align: center;
  border: solid 2px red;
  border-radius: 6px;
  background-color: pink;
  font-size: large;
  padding: 10px;
  margin: 12px 0 12px 0;
}

.RightAlign {
  text-align: right;
}

.BorderedTab {
  padding: 12px;
  border-left: solid 1px lightgray;
  border-right: solid 1px lightgray;
  border-bottom: solid 1px lightgray;
}

.SubtleBackground0 {
  background-color: #ddd;
}
.SubtleBackground1 {
  background-color: #eee;
}
