.TeamSelected {
	background-color: lightblue;
}
.Indented1 {
	margin-left: 36px;
}
.Indented2 {
	margin-left: 72px;
}


